const getResponse = async value => {
    const res = await fetch(
        `https://dev-geonames.travel-dev.com/api/v1/airport/search?term=${value}`,
        {
            Method: 'GET',
        }
    );
    const resjson = await res.json();
    return resjson;
}

export const getAutocompleteList = async value => {
    const resjson = await getResponse(value);

    // const arr = [];

    // for(var i = 0; i < resjson.data.length && i < 3; i++) {
    //     arr.push(
    //         <div className="autocomplete-listitem">
    //             <i className="autocomplete-city-dropdownIcon"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H4V2H2V4H0V13H10V4H8V2H6V0ZM4 6H2V8H4V6ZM2 9H4V11H2V9ZM6 9H8V11H6V9ZM6 6H8V8H6V6Z" transform="translate(12 9)" fill="#1C2F59" fill-opacity="0.5"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H4V2H2V5H0V20H10V5H8V2H6V0ZM2 7H4V9H2V7ZM6 7H8V9H6V7ZM4 10H2V12H4V10ZM6 10H8V12H6V10ZM4 13H2V15H4V13ZM6 13H8V15H6V13ZM4 16H2V18H4V16ZM6 16H8V18H6V16Z" transform="translate(2 2)" fill="#1C2F59" fill-opacity="0.5"></path></svg></i>
    //             <div className="autocomplete-listitem-text">{resjson.data[i].name}</div>
    //         </div>
    //     );

    //     if(resjson.data[i].children) {
    //         resjson.data[i].children.forEach(child => {
    //             // arr.push(child.name);
    //             arr.push(
    //                 <div className="autocomplete-listitem">
    //                     <i className="autocomplete-airport-dropdownIcon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 12V14L6.5 11V15L4.70588 16V18L8 17L11.2941 18V16L9.5 15V11L16 14V12L9.5 7V4C9.5 4 9.41177 0 8 0C6.58824 0 6.5 4 6.5 4V7L0 12Z" transform="translate(4 3)" fill="#1C2F59" fill-opacity="0.5"></path></svg></i>
    //                     <div className="autocomplete-listitem-text">{child.name}</div>
    //                 </div>
    //             );
    //         });
    //         if(arr.length >= 6) break;
    //     }
    // }
        
    // return arr;
    return resjson.data;
}