import React from 'react';
import './searchForm.styles.css';

import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DateRangePickerInputController, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import moment from 'moment';

import { getAutocompleteList } from '../../utils/api/autocomplete';
import isOutsideRange from '../../utils/dateCalc';

class SearchForm extends React.Component {

    constructor() {
        super();

        this.state = {
            tripType: 'Round-Trip',
            cabinType: 'Economy',

            isTripTypeHidden: true,
            isNumPaxHidden: true,
            isCabinTypeHidden: true,

            isFromAutocompleteHidden: true,
            isFromMobileAutocompleteHidden: true,
            isToAutocompleteHidden: true,
            isToMobileAutocompleteHidden: true,

            numAdults: 1,
            numChildren: 0,
            numInfants: 0,

            startDate: moment().add(1,'days'),
            endDate: null,

            inputFrom: '',
            inputTo: '',

            selectedFrom: '',
            selectedTo: '',

            inputFromAutocompleteList: [],
            inputToAutocompleteList: [],

            isWindowLessThan800: window.matchMedia('(max-width: 800px)'),

            // React-dates
            focused: false,
            mobileDatesFocused: false,
        }

        this.dropdownRef = React.createRef();
        this.inputFromRef = React.createRef();
        this.inputToRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        if(this.state.isWindowLessThan800.matches) {
            // document.getElementById('id-input-start-date').disabled = true;
            // document.getElementById('id-input-end-date').disabled = true;
            // Workaround on mobile version: Hide phone native keyboard when dates are focused
            document.getElementById('id-input-start-date').setAttribute('readOnly', true);
            document.getElementById('id-input-end-date').setAttribute('readOnly', true);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    
    render() {
        const numPax = this.state.numAdults + this.state.numChildren + this.state.numInfants;
        // const isWindowLessThan800 = window.matchMedia('(max-width: 800px)');
        return (
            <div className='search-form'>
                <div className='search-form-inner'>
                    <div className='form-options'>
                        <div className='form-options-option-wrapper'>
                            <div className='form-option form-options-trip-type' onClick={() => this.setState({isTripTypeHidden:false})}>
                                <span className='form-option-text form-options-trip-type-text'>
                                    {this.state.tripType}
                                </span>
                                <i className="form-options-caret-down"></i>
                            </div>
                            {
                            this.state.isTripTypeHidden ? null :
                            <div className='form-options-trip-type-dropdown' ref={this.dropdownRef}>
                                <div className='options-dropdown-item' onClick={() => this.setState({tripType:'Round-Trip', isTripTypeHidden:true})}>
                                    <input type="radio" id='round-trip' className='dropdown-option-radio' defaultChecked={this.state.tripType === "Round-Trip" ? true : false}/>
                                    <label htmlFor='round-trip' className="dropdown-option-radio-label">Round-Trip</label>
                                </div>
                                <div className='options-dropdown-item' onClick={() => this.setState({tripType:'One-Way', isTripTypeHidden:true})}>
                                    <input type="radio" id='one-way' className='dropdown-option-radio' defaultChecked={this.state.tripType === "One-Way" ? true : false}/>
                                    <label htmlFor='one-way' className="dropdown-option-radio-label">One-Way</label>
                                </div>
                            </div>
                            }
                        </div>
                        
                        <div className='form-options-option-wrapper'>
                            <div className='form-option form-options-num-pax' onClick={() => this.setState({isNumPaxHidden:false})}>
                                <span className='form-option-text form-options-num-pax-text'>
                                    {numPax} traveler{numPax > 1 ? 's' : ''}
                                </span>
                                <i className="form-options-caret-down"></i>
                            </div>
                            {
                            this.state.isNumPaxHidden ? null :
                            <div className='form-options-num-pax-dropdown' ref={this.dropdownRef}>
                                <div className='options-dropdown-item num-pax-options'>
                                    <span>{this.state.numAdults} Adults</span>
                                    <div className='num-pax-options-btns'>
                                        <span className='num-pax-options-btn' name="numAdults" onClick={() => this.handleNumpaxPlus("numAdults")}>+</span>
                                        <span className='num-pax-options-btn' name="numAdults" onClick={() => this.handleNumpaxMinus("numAdults")}>-</span>
                                    </div>
                                </div>
                                <div className='options-dropdown-item num-pax-options'>
                                    <span>{this.state.numChildren} Children</span>
                                    <div className='num-pax-options-btns'>
                                        <span className='num-pax-options-btn' onClick={() => this.handleNumpaxPlus("numChildren")}>+</span>
                                        <span className='num-pax-options-btn' onClick={() => this.handleNumpaxMinus("numChildren")}>-</span>
                                    </div>
                                </div>
                                <div className='options-dropdown-item num-pax-options'>
                                    <span>{this.state.numInfants} Infants</span>
                                    <div className='num-pax-options-btns'>
                                        <span className='num-pax-options-btn' onClick={() => this.handleNumpaxPlus("numInfants")}>+</span>
                                        <span className='num-pax-options-btn' onClick={() => this.handleNumpaxMinus("numInfants")}>-</span>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        
                        <div className='form-options-option-wrapper'>
                            <div className='form-option form-options-cabin-type' onClick={() => this.setState({isCabinTypeHidden:false})}>
                                <span className='form-option-text form-options-cabin-type-text'>
                                    {this.state.cabinType}
                                </span>
                                <i className="form-options-caret-down"></i>
                            </div>
                            {
                            this.state.isCabinTypeHidden ? null :
                            <div className='form-options-cabin-type-dropdown' ref={this.dropdownRef}>
                                <div className='options-dropdown-item' onClick={() => this.setState({cabinType:'Economy', isCabinTypeHidden:true})}>
                                    <input type="radio" id='economy' className='dropdown-option-radio' defaultChecked={this.state.cabinType === "Economy" ? true : false}/>
                                    <label htmlFor='economy' className="dropdown-option-radio-label">Economy</label>
                                </div>
                                <div className='options-dropdown-item' onClick={() => this.setState({cabinType:'Premium Economy', isCabinTypeHidden:true})}>
                                    <input type="radio" id='premium-economy' className='dropdown-option-radio' defaultChecked={this.state.cabinType === "Premium Economy" ? true : false}/>
                                    <label htmlFor='premium-economy' className="dropdown-option-radio-label">Premium Economy</label>
                                </div>
                                <div className='options-dropdown-item' onClick={() => this.setState({cabinType:'Business', isCabinTypeHidden:true})}>
                                    <input type="radio" id='business' className='dropdown-option-radio' defaultChecked={this.state.cabinType === "Business" ? true : false}/>
                                    <label htmlFor='business' className="dropdown-option-radio-label">Business</label>
                                </div>
                                <div className='options-dropdown-item' onClick={() => this.setState({cabinType:'First', isCabinTypeHidden:true})}>
                                    <input type="radio" id='first' className='dropdown-option-radio' defaultChecked={this.state.cabinType === "First" ? true : false}/>
                                    <label htmlFor='first' className="dropdown-option-radio-label">First</label>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className='form-input-fields'>
                        {
                        (this.state.isFromMobileAutocompleteHidden && this.state.isToMobileAutocompleteHidden) ? null :
                        <div className='input-fromTo-mobile-wrapper input-from-mobile-wrapper'>
                            <div className='input-fromTo-mobile'>
                                <div className='input-back-button-mobile' onClick={() => this.setState({isFromMobileAutocompleteHidden: true, isToMobileAutocompleteHidden: true})}>
                                    
                                </div>
                                <input
                                    id='textbox-mobile-tofocus'
                                    className='input-fromTo-textbox-mobile'
                                    type='text'
                                    name={this.state.isFromMobileAutocompleteHidden ? 'inputTo' : 'inputFrom'}
                                    placeholder={this.state.isFromMobileAutocompleteHidden ? 'To where?' : 'From where?'}
                                    value={this.state.isFromMobileAutocompleteHidden ? this.state.inputTo : this.state.inputFrom}
                                    onChange={this.handleChange}
                                    onFocus={(e) => e.target.select()}
                                />
                                <div className='input-clear-button-mobile'>
                                    CLEAR
                                </div>
                            </div>
                            
                            <div className='autocomplete-wrapper autocomplete-mobile-wrapper' onMouseDown={e => e.preventDefault()}>
                                {
                                this.state.isFromMobileAutocompleteHidden ?
                                this.convertAutocompleteIntoJSX(this.state.inputToAutocompleteList, {input: 'inputTo', hidden: 'isToMobileAutocompleteHidden', selected: 'selectedTo'})
                                :
                                this.convertAutocompleteIntoJSX(this.state.inputFromAutocompleteList, {input: 'inputFrom', hidden: 'isFromMobileAutocompleteHidden', selected: 'selectedFrom'})
                                }
                            </div>
                        </div>
                        }
                        <div className='input-fromTo-wrapper input-from-wrapper'>
                            <input
                                className='input-fromTo'
                                type='text'
                                name='inputFrom'
                                ref={this.inputFromRef}
                                placeholder='From where?'
                                value={this.state.inputFrom}
                                onChange={this.handleChange}
                                onFocus={(e) => {
                                    if(this.state.isWindowLessThan800.matches) {
                                        e.target.blur();
                                        this.setState({isFromMobileAutocompleteHidden: false}, () => {
                                            document.getElementById('textbox-mobile-tofocus').focus();
                                        });
                                    }
                                    else {
                                        this.setState({isFromAutocompleteHidden: false});
                                        console.log('selected');
                                        e.target.select();
                                    }
                                }}
                            />
                            {
                            this.state.isFromAutocompleteHidden ? null :
                            <div className='autocomplete-wrapper' ref={this.dropdownRef}>                            
                                {this.convertAutocompleteIntoJSX(this.state.inputFromAutocompleteList, {input: "inputFrom", hidden: "isFromAutocompleteHidden", selected: "selectedFrom"})}
                            </div>
                            }
                        </div>
                        <div className='input-fromTo-wrapper input-to-wrapper'>
                            <input 
                                type='text'
                                name='inputTo'
                                ref={this.inputToRef}
                                className='input-fromTo'
                                placeholder='To where?'
                                value={this.state.inputTo}
                                onChange={this.handleChange}
                                onFocus={(e) => {
                                    if(this.state.isWindowLessThan800.matches) {
                                        e.target.blur();
                                        this.setState({isToMobileAutocompleteHidden: false}, () => {
                                            document.getElementById('textbox-mobile-tofocus').focus();
                                        });
                                    }
                                    else {
                                        this.setState({isToAutocompleteHidden: false});
                                        console.log('selected');
                                        e.target.select();
                                    }
                                }}
                            />
                            {
                            this.state.isToAutocompleteHidden ? null :
                            <div className='autocomplete-wrapper' ref={this.dropdownRef}>
                                {this.convertAutocompleteIntoJSX(this.state.inputToAutocompleteList, {input: "inputTo", hidden: "isToAutocompleteHidden", selected: "selectedTo"})}
                            </div>
                            }
                        </div>
                        <div className='dates-wrapper'>
                            {this.displayReactDates(this.state.isWindowLessThan800)}
                            {/* {this.state.tripType === 'Round-Trip' ?
                            // <DateRangePickerInputController
                            <DateRangePicker
                                // orientation='vertical'
                                // startDate={this.state.startDate.format('Y M D')}
                                startDate={this.state.startDate}
                                startDateId='123' // DUMMY
                                endDate={this.state.endDate}
                                endDateId='456' // DUMMY
                                onDatesChange={({startDate, endDate}) => this.setState({startDate,endDate})}
                                numberOfMonths={2}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({focusedInput})}
                                noBorder={true}
                            />
                            :
                            <SingleDatePicker
                                date={this.state.startDate}
                                onDateChange={(startDate) => this.setState({startDate})}
                                numberOfMonths={2}
                                focused={this.state.focused}
                                onFocusChange={({focused}) => this.setState({focused})}
                                noBorder={true}
                            />
                            } */}
                        </div>
                        <div className='form-input-fields-submit' onClick={this.handleSubmit}>
                            <i className='submit-icon'></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleClickOutside = (event) => {
        if(this.dropdownRef.current) {
            if(!this.dropdownRef.current.contains(event.target)) {
                this.setState({isTripTypeHidden:true, isNumPaxHidden:true, isCabinTypeHidden:true,});
                if(this.inputFromRef.current && !this.inputFromRef.current.contains(event.target)) {
                    this.setState({isFromAutocompleteHidden: true});
                }
                if(this.inputToRef.current && !this.inputToRef.current.contains(event.target)) {
                    this.setState({isToAutocompleteHidden: true});
                }
            }
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        // console.log(event.target);
        this.setState({ [name]: value });
        this.updateAutocomplete(name, value);
    }

    handleNumpaxPlus = (name) => {
        const tempState = {
            numAdults: this.state.numAdults,
            numChildren: this.state.numChildren,
            numInfants: this.state.numInfants,
        }

        tempState[name]++;

        const { numAdults, numChildren, numInfants } = tempState;
        const value = this.state[name];

        if(numAdults + numChildren + numInfants > 9 || numChildren > numAdults*2 || numInfants > numAdults || numInfants + numChildren > numAdults*2) {
            return;
        }
        else {
            this.setState({ [name]: value + 1 });
        }
    }

    handleNumpaxMinus = (name) => {
        const tempState = {
            numAdults: this.state.numAdults,
            numChildren: this.state.numChildren,
            numInfants: this.state.numInfants,
        }
        
        tempState[name]--;
        
        const { numAdults, numChildren, numInfants } = tempState;
        const value = this.state[name];
        
        if(tempState[name] < 0 || numAdults <= 0 || numAdults*2 < numChildren || numAdults < numInfants || numAdults*2 < numChildren + numInfants) {
            return;
        }
        else {
            this.setState({ [name]: value - 1 });
        }
    }

    updateAutocomplete = async (name, value) => {
        const autocompleteList = await getAutocompleteList(value);

        if(name === 'inputFrom') this.setState({inputFromAutocompleteList: autocompleteList});
        else if(name === 'inputTo') this.setState({inputToAutocompleteList: autocompleteList});
    }

    handleSubmit = () => {
        if(this.state.selectedFrom === '' || this.state.selectedTo === '' || this.state.tripType === 'Round-Trip' && this.state.endDate === null) return;
        const base = 'https://scholartrip.com/search/';

        const urlParams = this.buildParam();
        const redirectUrl = `${base}${this.state.selectedFrom}-${this.state.selectedTo}/${this.state.startDate.format('YYYY-MM-DD')}/${this.state.tripType === 'Round-Trip' ? this.state.endDate.format('YYYY-MM-DD') : ''}${urlParams}`;
        window.location.href = redirectUrl;
    }

    buildParam = () => {
        const paramList = [];

        const numPax = this.state.numAdults + this.state.numChildren + this.state.numInfants;
        
        if(numPax > 1) {
            paramList.push(`adt=${this.state.numAdults}`);
            
            if(this.state.numChildren > 0) paramList.push(`chd=${this.state.numChildren}`);
            if(this.state.numInfants > 0) paramList.push(`inf=${this.state.numInfants}`);
        }

        if(this.state.cabinType !== 'Economy') {
            paramList.push(`cabin=${this.state.cabinType === 'Premium Economy' ? 'premium-economy': this.state.cabinType === 'Business' ? 'business' : 'first'}`);
        }

        if(paramList.length) {
            let ret = '?';
            paramList.forEach((child, index) => {
                ret += child;
                if(index !== paramList.length-1) ret += '&';
            });
            return ret;
        }
        return '';
    }

    convertAutocompleteIntoJSX = (autocompleteList, name) => {
        const arr = [];

        for(var i = 0; i < autocompleteList.length && i < 3; i++) {
            arr.push(
                <div 
                    key={`autocomplete-listitem${i}`} 
                    className="autocomplete-listitem" 
                    data-iata={`${autocompleteList[i].iata}`} 
                    onMouseDown={e => e.preventDefault()} 
                    onClick={e => this.handleSelect(e, name)}
                >
                    <i className="autocomplete-city-dropdownIcon"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 0H4V2H2V4H0V13H10V4H8V2H6V0ZM4 6H2V8H4V6ZM2 9H4V11H2V9ZM6 9H8V11H6V9ZM6 6H8V8H6V6Z" transform="translate(12 9)" fill="#1C2F59" fillOpacity="0.5"></path><path fillRule="evenodd" clipRule="evenodd" d="M6 0H4V2H2V5H0V20H10V5H8V2H6V0ZM2 7H4V9H2V7ZM6 7H8V9H6V7ZM4 10H2V12H4V10ZM6 10H8V12H6V10ZM4 13H2V15H4V13ZM6 13H8V15H6V13ZM4 16H2V18H4V16ZM6 16H8V18H6V16Z" transform="translate(2 2)" fill="#1C2F59" fillOpacity="0.5"></path></svg></i>
                    <div className="autocomplete-listitem-text">{autocompleteList[i].name}</div>
                </div>
            );
            if(autocompleteList[i].children) {
                autocompleteList[i].children.forEach((child, index) => {
                    arr.push(
                        <div key={`autocomplete-listitem${i}-${index}`} className="autocomplete-listitem" data-iata={`${child.iata}`} onMouseDown={e => e.preventDefault()} onClick={e => this.handleSelect(e, name)}>
                            <i className="autocomplete-airport-dropdownIcon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 12V14L6.5 11V15L4.70588 16V18L8 17L11.2941 18V16L9.5 15V11L16 14V12L9.5 7V4C9.5 4 9.41177 0 8 0C6.58824 0 6.5 4 6.5 4V7L0 12Z" transform="translate(4 3)" fill="#1C2F59" fillOpacity="0.5"></path></svg></i>
                            <div className="autocomplete-listitem-text">{child.name}</div>
                        </div>
                    );
                });
                if(arr.length >= 6) break;
            }
        }
        return arr;
    }

    handleSelect = (e, name) => {
        console.log(e.currentTarget.innerText);
        this.setState({
            [name.input]: e.currentTarget.innerText,
            [name.selected]: e.currentTarget.dataset.iata,
            [name.hidden]: true,
        });
        this.inputFromRef.current.blur();
        this.inputToRef.current.blur();
    }

    // <DayPickerRangeController 
    //                     orientation='verticalScrollable'
    //                     startDate={this.state.startDate}
    //                     endDate={this.state.endDate}
    //                     onDatesChange={({startDate, endDate}) => this.setState({startDate,endDate})}
    //                     focusedInput={this.state.focusedInput}
    //                     onFocusChange={focusedInput => this.setState({ focusedInput })}
    //                     initialVisibleMonth={() => moment().add(2, "M")}
    //                 />
    displayReactDates = (mq) => {
        if(mq.matches) {
            if(this.state.mobileDatesFocused) {
                document.body.style.overflow = 'hidden';
                // document.getElementsByClassName('header')[0].style.display = 'none';
                // document.getElementById('id-input-start-date').readOnly = true;
                // document.getElementById('id-input-end-date').readOnly = true;
                // document.getElementById('id-input-end-date').style.display = 'none';
                // document.getElementById('id-input-start-date').setAttribute('readOnly','true');
                // document.getElementById('id-input-end-date').setAttribute('readOnly','true');
            }
            else {
                // console.log('else');
            }
            return(
                // this.state.tripType === 'Round-Trip' ?
                <div>
                    <div className={this.state.tripType==='Round-Trip' ? 'mobile-round-trip-dates-input-wrapper' : 'mobile-one-way-date-input-wrapper'} onClick={() => this.setState({mobileDatesFocused: true})}>
                        <DateRangePickerInputController
                            startDate={this.state.startDate}
                            startDateId='id-input-start-date' // DUMMY
                            endDate={this.state.endDate}
                            endDateId='id-input-end-date' // DUMMY
                            onDatesChange={({startDate, endDate}) => this.setState({startDate,endDate})}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => { 
                                this.setState({focusedInput});
                            }}
                            noBorder={true}
                        />
                    </div>
                    {this.state.mobileDatesFocused ? 
                    <div className='mobile-calendar-wrapper'>
                        <div className='mobile-calendar-dates-input-wrapper'>
                            <div className='input-back-button-mobile' onClick={() => {
                                this.setState({mobileDatesFocused: false});
                                document.body.style.overflow = 'auto';
                            }}>
                                
                            </div>
                            <input type="text" className='mobile-calendar-dates-input' name="start date" value={this.state.startDate && this.state.startDate.format('MM-DD-YYYY')} readOnly />
                            <input type="text" className='mobile-calendar-dates-input' name="end date" value={this.state.endDate && this.state.endDate.format('MM-DD-YYYY')} readOnly />
                        </div>
                        <DayPickerRangeController 
                            orientation='verticalScrollable'
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onDatesChange={({startDate, endDate}) => this.setState({startDate,endDate})}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState(this.state.tripType==='Round-Trip' ? { focusedInput: !focusedInput ? 'startDate' : focusedInput } : { focusedInput: 'startDate' })}
                            // onFocusChange={()=>this.setState({ focusedInput: 'startDate' })}
                            initialVisibleMonth={() => moment()}
                            numberOfMonths={13}
                            isOutsideRange={day => isOutsideRange(day, moment())}
                            // isOutsideRange={day => true}
                            // isDayHighlighted={() => false}
                        />
                        <div className='mobile-calendar-options-wrapper'>
                            <button className='mobile-calendar-option-save' onClick={() => {
                                    this.setState({mobileDatesFocused: false});
                                    document.body.style.overflow = 'auto';
                                }}>Save
                            </button>
                        </div>
                    </div>
                    :
                    null}
                </div>
                // :
                // <SingleDatePicker
                //     date={this.state.startDate}
                //     onDateChange={(startDate) => this.setState({startDate})}
                //     numberOfMonths={2}
                //     focused={this.state.focused}
                //     onFocusChange={({focused}) => this.setState({focused})}
                //     noBorder={true}
                // />
            );
        }
        else {
            return(
                this.state.tripType === 'Round-Trip' ?
                <DateRangePicker
                    startDate={this.state.startDate}
                    startDateId='123' // DUMMY
                    endDate={this.state.endDate}
                    endDateId='456' // DUMMY
                    onDatesChange={({startDate, endDate}) => this.setState({startDate,endDate})}
                    numberOfMonths={2}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({focusedInput})}
                    noBorder={true}
                    isOutsideRange={day => isOutsideRange(day, moment())}
                />
                :
                <SingleDatePicker
                    date={this.state.startDate}
                    onDateChange={(startDate) => this.setState({startDate})}
                    numberOfMonths={2}
                    focused={this.state.focused}
                    onFocusChange={({focused}) => this.setState({focused})}
                    noBorder={true}
                    isOutsideRange={day => isOutsideRange(day, moment())}
                />
            );
        }
    }
}

export default SearchForm;