import React from 'react';

import Cover from '../../components/cover/cover.component';
import SearchCard from '../../components/searchCard/searchCard.component';
import BlogSnippet from '../../components/info/info.component';

import { connect } from 'react-redux';
import { setCoverImage, setCardTitle, setCardParagraph, setCardSection, setInfoSection } from '../../redux/pageConfig/pageConfig.actions';

import { getPageConfig } from '../../utils/api/wordpress';


class Template1 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            config: []
        }
    }

    componentDidMount = async () => {
        const destination = this.props.location.pathname.substring(1);
        const config = await getPageConfig(destination);

        const { setCoverImage, setCardTitle, setCardParagraph, setCardSection, setInfoSection } = this.props;
        setCoverImage(config.cover_image.url);
        setCardTitle(config.card_title);
        setCardParagraph(config.card_paragraph);
        setCardSection(config.cards);
        setInfoSection(config.info);
    }

    render() {

        const des = this.props.location.pathname.substring(1);

        return(
            <div className='master'>
                
                <Cover des={des} />
                <SearchCard des={des} />
                <BlogSnippet />

            </div>
        );
    }
    
}

const mapDispatchToProps = dispatch => ({
    setCoverImage: image => dispatch(setCoverImage(image)),
    setCardTitle: title => dispatch(setCardTitle(title)),
    setCardParagraph: paragraph => dispatch(setCardParagraph(paragraph)),
    setCardSection: cards => dispatch(setCardSection(cards)),
    setInfoSection: info => dispatch(setInfoSection(info)),
});

export default connect(null, mapDispatchToProps)(Template1);