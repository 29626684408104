import React from 'react';
import './info.styles.css';

import image1 from '../../assets/girl-with-bag-at-airport.webp';
import image2 from '../../assets/pexels-photo-1563678.jpeg';

import { connect } from 'react-redux';

class Info extends React.Component {

    constructor() {
        super();
    }

    render() {
        const { info } = this.props;
        const infoList = Object.values(info);
        return(
            <div className='info-items'>
                {infoList.map((child, index) => (
                    index%2 == 0 ?  
                    <div key={`info-item${index}`} className='info-item'>
                        <div className='info-item-image-wrapper'>
                            <img src={image1} alt="girl"/>
                        </div>
                        <div className='info-item-textblock'>
                            <h1>{child.topic}</h1>
                            <div>{this.breakParagraphs(child.text_block)}</div>
                        </div>
                    </div>
                    :
                    <div key={`info-item${index}`} className='info-item'>
                        <div className='info-item-textblock'>
                            <h1>{child.topic}</h1>
                            <div>{this.breakParagraphs(child.text_block)}</div>
                        </div>
                        <div className='info-item-image-wrapper'>
                            <img src={image2} alt="taxi"/>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    breakParagraphs = text => {
        const textList = text.split('\r\n');
        return(
            textList.map((child, index) => (
                <p key={`p${index}`}>{child}<br /></p>
            ))
        );
    }
}

const mapStateToProps = (state) => ({
    info: state.pageConfig.info,
});

export default connect(mapStateToProps)(Info);