import moment from 'moment';

const isBeforeDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

const isOneYearAfter = (a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    
    const aYear = a.year();
    const aMonth = a.month();

    const bYear = b.year();
    const bMonth = b.month();

    const isNextYear = aYear > bYear;
    const isSameMonth = aMonth === bMonth;

    if(isNextYear && isSameMonth) return a.date() > b.date();
    if(isNextYear) return aMonth > bMonth;
    return false;
}

const isOutsideRange = (a, b) => {
    // const today = moment();
    // console.log(`${today.month()}-${today.date()}-${today.year()}`);
    // console.log(moment().format('MM-DD-YYYY'));
    // console.log(`${b.month()}-${b.day()}-${b.year()}`);
    // console.log(`${a.month()}-${a.date()}-${a.year()}: ${isBeforeDay(a, b) || isOneYearAfter(a, b)}`);
    // console.log(`${a.format('MM-DD-YYYY')}: ${isBeforeDay(a, b)}`);
    return isBeforeDay(a, b) || isOneYearAfter(a, b);
}

export default isOutsideRange;