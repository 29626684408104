import React from 'react';
import './searchCard.styles.css';

import moment from 'moment';

import { connect } from 'react-redux';

class SearchCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cardTextHeader: '',
            cardTextParagraph: '',
        }
    }

    render = () => {
        const { cardTitle, cardParagraph, cards } = this.props;
        const cardList = Object.values(cards);
        console.log(cardList);
        return(
            <div className='card-section'>
                <div className='card-section-inner'>
                    <div className='card-section-inner-textblock'>
                        <h1>{cardTitle}</h1>
                        <p>{cardParagraph}</p>
                    </div>
                    <div className='searchCard-items'>
                        {cardList.map((child, index) => (
                            <div key={`searchCard-item${index+1}`} className='searchCard-item'>
                                <div className='searchCard-od'>{child.origin} to {child.destination}</div>
                                <div className='searchCard-date'>{moment(child.departure_date).format('MMM D')}{child.trip_type === 'round-trip' ? ` - ${moment(child.return_date).format("MMM D")}` : null}</div>
                                <div className='searchCard-triptype'>{child.trip_type} from*</div>
                                <div className='searchCard-price'>${child.price}</div>
                                <div className='searchCard-btn' onClick={() => this.handleCardClick(child)}>Search Now</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    handleCardClick = (card) => {
        const base = 'https://scholartrip.com/search/';
        const origin_iata = card.origin_iata;
        const destination_iata = card.destination_iata;

        const redirectUrl = `${base}${origin_iata}-${destination_iata}/${moment(card.departure_date).format('YYYY-MM-DD')}${card.trip_type === 'round-trip' ? `/${moment(card.return_date).format('YYYY-MM-DD')}`:''}`;
        window.location.href = redirectUrl;
    }
}

const mapStateToProps = state => ({
    cardTitle: state.pageConfig.cardTitle,
    cardParagraph: state.pageConfig.cardParagraph,
    cards: state.pageConfig.cards,
})

export default connect(mapStateToProps)(SearchCard);