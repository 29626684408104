export const setCoverImage = image => ({
    type: 'SET_COVER_IMAGE',
    payload: image
});

export const setCardTitle = title => ({
    type: 'SET_CARD_TITLE',
    payload: title
});

export const setCardParagraph = paragraph => ({
    type: 'SET_CARD_PARAGRAPH',
    payload: paragraph
});

export const setCardSection = cards => ({
    type: 'SET_CARD_SECTION',
    payload: cards,
});

export const setInfoSection = info => ({
    type: 'SET_INFO_SECTION',
    payload: info
});
