const INITIAL_STATE = {
    coverImage: null,
    cardTitle: '',
    cardParagraph: '',
    info: {},
    cards: {},
}

const pageConfigReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_COVER_IMAGE':
            return({
                ...state,
                coverImage: action.payload
            });
        case 'SET_CARD_TITLE':
            return({
                ...state,
                cardTitle: action.payload
            });
        case 'SET_CARD_PARAGRAPH':
            return({
                ...state,
                cardParagraph: action.payload
            });
        case 'SET_CARD_SECTION':
            return({
                ...state,
                cards: action.payload
            });
        case 'SET_INFO_SECTION':
            return({
                ...state,
                info: action.payload
            });
        default:
            return state;
    }
}

export default pageConfigReducer;