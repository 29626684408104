import React from 'react';
import './App.css';

import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';

import Template1 from './pages/template1/template1.component';

import { Switch, Route, Redirect } from 'react-router-dom';

class App extends React.Component {

  constructor() {
    super();
  }

  render() {
    document.title = 'Meow';
    return (
      <div>
        <Header />
        <Switch>
          <Route exact path='/:pagename' component={Template1} />
          <Route path='/'>
            <Redirect to='/london' />
          </Route>
        </Switch>
        <Footer />
      </div>
    );
  }

  getPageName = async (des) => {
    const res = await fetch(
      `http://localhost:8000/wp-json/wp/v2/template1_settings?slug=${des}`,
      {
        Method: 'GET'
      }
    )
    const resjson = await res.json();
    this.setState({ cardTextParagraph: resjson[0].acf.card_paragraph });
  }
}

export default App;
