const TEST = 'localhost:5555';
const PROD = 'localhost';

const getResponse = async destination => {
    const res = await fetch(
        `/wp-json/wp/v2/template1_settings?slug=${destination}`,
        {
            Method: 'GET',
        }
    );
    const resjson = await res.json();
    return resjson;
}

export const getPageConfig = async destination => {
    const resjson = await getResponse(destination);
    return resjson[0].acf;
}