import React from 'react';
import './cover.styles.css';

import SearchForm from '../searchForm/searchForm.component';

import { connect } from 'react-redux';

class Cover extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { coverImage } = this.props;
        return(
            <div className='cover'>
                <div className='cover-image-wrapper'>
                    <img src={coverImage} alt="cover-image"/>
                </div>
                <SearchForm/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    coverImage: state.pageConfig.coverImage,
});


export default connect(mapStateToProps)(Cover);